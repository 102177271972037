import { graphql } from 'gatsby';
import React from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from '~/components/Layout';
import FlexibleContent from '~/components/FlexibleContent';
import usePartnerPreviews from '~/hooks/usePartnerPreviews';
import Stories from '~/components/organisms/Stories';
import HeroTwoColumn from '~/components/organisms/HeroTwoColumn';
import CustomSEO from '~/components/seo/CustomSEO';

export default function PartnersPage({ data: { wpPage } }) {
  const page = wpPage.partnerStories.partners;
  const partnerPreviews = usePartnerPreviews();

  const content = [
    ...page.content,
    ...wpPage.page.page.content,
  ]

  const customSeo = {
    ...wpPage?.seo,
    image: page.heroTwoColumn?.image
  }

  return (
    <Layout headerWhite={wpPage.page.page.whiteHeader} backgroundColour={wpPage.page.page.backgroundColour} showLogin={wpPage.page.page.showLogin} footerCtaType={wpPage.page.page.footerCtaType} footerResourcesType={wpPage.page.page.footerResourcesType} pageLanguage={wpPage.page.page.pageLanguage}>
      <Seo post={wpPage} />
      <CustomSEO seo={customSeo} />
      <HeroTwoColumn {...page} />
      <FlexibleContent content={content} />
      <Stories partnerPreviews={partnerPreviews} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query partnersQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      id
      ...SEO
      page {
        page {
          backgroundColour
          whiteHeader
          showLogin
          footerCtaType
          footerResourcesType
          pageLanguage
          content {
            ... on WpPage_Page_Page_Content_Reviews {
              fieldGroupName
              reviews {
                fieldGroupName
                heading
                type
                rating
                count
              }
            }
          }
        }
      }
      partnerStories {
        partners {
          content {
            ... on WpPage_Partnerstories_Partners_Content_FeaturedStories {
              fieldGroupName
            }
            ... on WpPage_Partnerstories_Partners_Content_Cta {
              fieldGroupName
              cta {
                fieldGroupName
                illustration {
                  ...Image
                }
                textCard {
                  illustration {
                    ...Image
                  }
                  subheading
                  subheadingColor
                  alignText
                  heading {
                    text
                    type
                  }
                  content
                  buttons {
                    button {
                      button {
                        button {
                          ...Button
                        }
                      }
                      type
                    }
                  }
                }
              }
            }
            ... on WpPage_Partnerstories_Partners_Content_Quote {
              fieldGroupName
              quote {
                fieldGroupName
                quote
                backgroundColour {
                  colour
                }
                author {
                  name
                  role
                }
                caption
                illustration {
                  ...Image
                }
              }
            }
            ... on WpPage_Partnerstories_Partners_Content_FeaturedStory {
              fieldGroupName
              featuredStory {
                fieldGroupName
                alignImage
                story {
                  ... on WpPartnerStory {
                    id
                    uri
                    title
                    excerpt
                    featuredImage {
                      node {
                        ...Image
                      }
                    }
                    partnerStoryItem {
                      partnerStoryItem {
                        stat {
                          content
                          heading
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on WpPage_Partnerstories_Partners_Content_FeaturedStories {
              fieldGroupName
              featuredStories {
                fieldGroupName
                stories {
                  story {
                    ... on WpPartnerStory {
                      id
                      uri
                      title
                      featuredImage {
                        node {
                          ...Image
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          heroTwoColumn {
            backgroundColour
            showArrow
            textCard {
              illustration {
                ... Image
              }
              subheading
              subheadingColor
              alignText

              heading {
                text
                type
              }
              content
              buttons {
                button {
                  button {
                    button {
                      ... Button
                    }
                  }
                  type
                }
              }
            }
            image {
              ... Image
            }
          }
        }
      }
    }
  }
`;
